import React from "react";
import { Layout, Col, Row, Image, Card, Carousel, Divider } from "antd";
const { Header, Footer, Content } = Layout;

import "./App.css";

const cards = [
    { title: "媒体全覆盖", description: "全面覆盖数万家主流新闻资讯网站及客户端，微博、微信等社交网络，抖音、快手、小红书等视频平台 ，每日新增数据6000万+" },
    { title: "专业团队", description: "卓越的客户服务理念，领先的舆情监测技术，行业经验丰富的专业人员，为客户需求提供定制化服务" },
    { title: "技术优势", description: "强大的算力支撑海量数据的采集 ，智能化数据处理体系，数据可视化展示平台，全自动一键式制作报告" },
    { title: "丰富案例", description: "二十年沉淀，坚持打造舆情行业高端口碑，1000+用户遍及国内外各个领域" },
];

const css = [
    { name: "1fund" },
    { name: "amazon" },
    { name: "angel" },
    { name: "apple" },
    { name: "coca" },
    { name: "embraer" },
    { name: "hp" },
    { name: "ibm" },
    { name: "invis" },
    { name: "jd" },
    { name: "kn" },
    { name: "oneplus" },
    { name: "pd" },
    { name: "redbull" },
    { name: "sap" },
    { name: "siemens" },
    { name: "tesla" },
    { name: "watsons" },
    { name: "wd" },
    { name: "yw" },
];

function App() {
    return (
        <Layout>
            <Header className="header">
                <Row className="row-center">
                    <Col span={10} className="main-img">
                        <Image width={130} preview={false} src="/images/logo.svg" />
                    </Col>
                    <Col span={12}>
                        <span className="head-text">联系电话：010-64465420 &nbsp; &nbsp; 客服邮箱：sales@e-press.com.cn</span>
                    </Col>
                    <Col span={1}>
                        <Image width={60} src="/images/qrcode.jpg" />
                    </Col>
                </Row>
            </Header>
            <Content className="content">
                <Row justify="center" className="row-center">
                    <Col span={10}>
                        <div className="main-div">
                            <div className="main-text1"> | 我们</div>
                            <div className="main-text2">国内领先的媒体信息监测机构</div>
                            <div className="main-text3">致力于为客户提供全面、准确、及时的数据服务</div>
                        </div>
                    </Col>
                    <Col span={13}>
                        <Image width={800} preview={false} src="/images/rightimg.png" />
                    </Col>
                </Row>

                <Row justify="space-evenly" gutter={[80, 40]} className="row-center">
                    {cards.map((x, i) => {
                        return (
                            <Col span={12}>
                                <Card hoverable className="card" cover={<Image height={180} preview={false} src={`/images/${i + 1}.png`} />}>
                                    <div className="card-title">{x.title}</div>
                                    <div>
                                        <Divider />
                                    </div>
                                    <div className="card-description">{x.description}</div>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>

                <div className="carousel">
                    <Carousel autoplay autoplaySpeed={1500} dots={false} slidesToShow={10} slidesToScroll={1} effect="scrollx">
                        {css.map((x, i) => {
                            return (
                                <div key={i}>
                                    <Image height={40} preview={false} src={`/images/cs/${x.name}.png`} />
                                </div>
                            );
                        })}
                    </Carousel>
                </div>
            </Content>
            <Footer className="footer">
                <div>易捷世纪资讯科技（北京）有限公司</div>
                <div className="icp">
                    <a href="https://beian.miit.gov.cn/" target="blank">
                        京ICP备11037573号-2
                    </a>
                </div>
            </Footer>
        </Layout>
    );
}

export default App;
